import React from 'react'
import { Link, graphql } from 'gatsby'
import Layout from '../../../components/Layout'
import SEO from '../../../components/seo'
// import MySwiper2 from '../../../components/Swiper2'
import { LazyLoadImage } from 'react-lazy-load-image-component'
// images
import f1 from '../../../assets/images/gates/flat-top/f1.jpg'
import f2 from '../../../assets/images/gates/flat-top/f2.jpg'
import f3 from '../../../assets/images/gates/flat-top/f3.jpg'
import f4 from '../../../assets/images/gates/flat-top/f4.jpg'
import f5 from '../../../assets/images/gates/flat-top/f5.jpg'
import f6 from '../../../assets/images/gates/flat-top/f6.jpg'
import f7 from '../../../assets/images/gates/flat-top/f7.jpg'
import f8 from '../../../assets/images/gates/flat-top/f8.jpg'
import f9 from '../../../assets/images/gates/flat-top/f9.jpg'
import f10 from '../../../assets/images/gates/flat-top/f10.jpg'
import f11 from '../../../assets/images/gates/flat-top/f11.jpg'
import f12 from '../../../assets/images/gates/flat-top/f12.jpg'
import f13 from '../../../assets/images/gates/flat-top/f13.jpg'

const gates = [
  {
    label: 'F1',
    img: f1,
    pdf:
      'https://res.cloudinary.com/dnla85pdq/image/upload/v1577085094/jjgates/flat-top-gates-pdf/F1.pdf',
  },
  {
    label: 'F2',
    img: f2,
    pdf:
      'https://res.cloudinary.com/dnla85pdq/image/upload/v1577085094/jjgates/flat-top-gates-pdf/F2.pdf',
  },
  {
    label: 'F3',
    img: f3,
    pdf:
      'https://res.cloudinary.com/dnla85pdq/image/upload/v1577085094/jjgates/flat-top-gates-pdf/F3.pdf',
  },
  {
    label: 'F4',
    img: f4,
    pdf:
      'https://res.cloudinary.com/dnla85pdq/image/upload/v1577085094/jjgates/flat-top-gates-pdf/F4.pdf',
  },
  {
    label: 'F5',
    img: f5,
    pdf:
      'https://res.cloudinary.com/dnla85pdq/image/upload/v1577085094/jjgates/flat-top-gates-pdf/F5.pdf',
  },
  {
    label: 'F6',
    img: f6,
    pdf:
      'https://res.cloudinary.com/dnla85pdq/image/upload/v1577085094/jjgates/flat-top-gates-pdf/F6.pdf',
  },
  {
    label: 'F7',
    img: f7,
    pdf:
      'https://res.cloudinary.com/dnla85pdq/image/upload/v1577085094/jjgates/flat-top-gates-pdf/F7.pdf',
  },
  {
    label: 'F8',
    img: f8,
    pdf:
      'https://res.cloudinary.com/dnla85pdq/image/upload/v1577085094/jjgates/flat-top-gates-pdf/F8.pdf',
  },
  {
    label: 'F9',
    img: f9,
    pdf:
      'https://res.cloudinary.com/dnla85pdq/image/upload/v1577085094/jjgates/flat-top-gates-pdf/F9.pdf',
  },
  {
    label: 'F10',
    img: f10,
    pdf:
      'https://res.cloudinary.com/dnla85pdq/image/upload/v1577085094/jjgates/flat-top-gates-pdf/F10.pdf',
  },
  {
    label: 'F11',
    img: f11,
    pdf:
      'https://res.cloudinary.com/dnla85pdq/image/upload/v1577085094/jjgates/flat-top-gates-pdf/F11.pdf',
  },
  {
    label: 'F12',
    img: f12,
    pdf:
      'https://res.cloudinary.com/dnla85pdq/image/upload/v1577085094/jjgates/flat-top-gates-pdf/F12.pdf',
  },
  {
    label: 'F13',
    img: f13,
    pdf:
      'https://res.cloudinary.com/dnla85pdq/image/upload/v1577085094/jjgates/flat-top-gates-pdf/F13.pdf',
  },
]

class flatTopGatesPage extends React.Component {
  render() {
    const siteTitle = 'Flat Top Gates - J & J Automatic Service & Design'
    const siteDescription =
      'Do you need an installation of a flat top gate in Arlington, Dallas, and Fort Worth area? See our designs flat top gates.'

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title="Flat Top Gates - J&J Automatic Service & Design"
          description={siteDescription}
        />

        <div className="container-fluid text-center headline gates-headline">
          <div className="container">
            <h1 className="text-white m-0">Flat Top Gate Designs</h1>
            <hr />
          </div>
        </div>

        <div className="container d-padding-t d-padding-b text-center flat-top-gates">
          <div className="row">
            <div className="col-md-12 mb-5">
              <p className="mx-auto">
                J&J Gate Services designs, build and installs flat top gates for
                both residential and commercial properties. These are the flat
                top designs we have available. We’re happy to assist you with
                creating the custom design you are envisioning as well.
                <strong>
                  Click any of the images below to access the pdf version
                  available to print or download.
                </strong>{' '}
                Give us a call at{' '}
                <a href="tel:+1-817-466-2794">(817) 466-2794</a> to learn more
                about <Link to="/gates/services">gate installation</Link> in
                Dallas-Fort Worth, TX and the surrounding area.
              </p>
            </div>
            {gates.map(gate => (
              <div className="col-md-4">
                <div className="img-thumbnail shadow my-3 pt-3">
                  <a href={gate.pdf} target="_blank" rel="noopener noreferrer">
                    <LazyLoadImage src={gate.img} alt="Bell Gate" />
                  </a>
                  <h5 class="mt-3">{gate.label}</h5>
                </div>
              </div>
            ))}
            {/* <MySwiper2 slidesperview={3}>
              <div>
                <a
                  href="https://res.cloudinary.com/dnla85pdq/image/upload/v1577085444/jjgates/flat-top-gates-pdf/F1.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <LazyLoadImage src={f1} alt="Flat Top Gate" />
                </a>

                <h5 class="mt-0">F1</h5>
              </div>
              <div>
                <a
                  href="https://res.cloudinary.com/dnla85pdq/image/upload/v1577085445/jjgates/flat-top-gates-pdf/F2.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <LazyLoadImage src={f2} alt="Flat Top Gate" />
                </a>

                <h5 class="mt-0">F2</h5>
              </div>
              <div>
                <a
                  href="https://res.cloudinary.com/dnla85pdq/image/upload/v1577085445/jjgates/flat-top-gates-pdf/F3.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <LazyLoadImage src={f3} alt="Flat Top Gate" />
                </a>

                <h5 class="mt-0">F3</h5>
              </div>
              <div>
                <a
                  href="https://res.cloudinary.com/dnla85pdq/image/upload/v1577085445/jjgates/flat-top-gates-pdf/F4.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <LazyLoadImage src={f4} alt="Flat Top Gate" />
                </a>

                <h5 class="mt-0">F4</h5>
              </div>
              <div>
                <a
                  href="https://res.cloudinary.com/dnla85pdq/image/upload/v1577085445/jjgates/flat-top-gates-pdf/F5.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <LazyLoadImage src={f5} alt="Flat Top Gate" />
                </a>

                <h5 class="mt-0">F5</h5>
              </div>
              <div>
                <a
                  href="https://res.cloudinary.com/dnla85pdq/image/upload/v1577085444/jjgates/flat-top-gates-pdf/F6.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <LazyLoadImage src={f6} alt="Flat Top Gate" />
                </a>

                <h5 class="mt-0">F6</h5>
              </div>
              <div>
                <a
                  href="https://res.cloudinary.com/dnla85pdq/image/upload/v1577085444/jjgates/flat-top-gates-pdf/F7.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <LazyLoadImage src={f7} alt="Flat Top Gate" />
                </a>

                <h5 class="mt-0">F7</h5>
              </div>
              <div>
                <a
                  href="https://res.cloudinary.com/dnla85pdq/image/upload/v1577085445/jjgates/flat-top-gates-pdf/F8.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <LazyLoadImage src={f8} alt="Flat Top Gate" />
                </a>

                <h5 class="mt-0">F8</h5>
              </div>
              <div>
                <a
                  href="https://res.cloudinary.com/dnla85pdq/image/upload/v1577085444/jjgates/flat-top-gates-pdf/F9.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <LazyLoadImage src={f9} alt="Flat Top Gate" />
                </a>

                <h5 class="mt-0">F9</h5>
              </div>
              <div>
                <a
                  href="https://res.cloudinary.com/dnla85pdq/image/upload/v1577085444/jjgates/flat-top-gates-pdf/F10.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <LazyLoadImage src={f10} alt="Flat Top Gate" />
                </a>

                <h5 class="mt-0">F10</h5>
              </div>
              <div>
                <a
                  href="https://res.cloudinary.com/dnla85pdq/image/upload/v1577085444/jjgates/flat-top-gates-pdf/F11.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <LazyLoadImage src={f11} alt="Flat Top Gate" />
                </a>

                <h5 class="mt-0">F11</h5>
              </div>
              <div>
                <a
                  href="https://res.cloudinary.com/dnla85pdq/image/upload/v1577085444/jjgates/flat-top-gates-pdf/F12.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <LazyLoadImage src={f12} alt="Flat Top Gate" />
                </a>

                <h5 class="mt-0">F12</h5>
              </div>
              <div>
                <a
                  href="https://res.cloudinary.com/dnla85pdq/image/upload/v1577085444/jjgates/flat-top-gates-pdf/F13.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <LazyLoadImage src={f13} alt="Flat Top Gate" />
                </a>

                <h5 class="mt-0">F13</h5>
              </div>
            </MySwiper2> */}
          </div>
        </div>
      </Layout>
    )
  }
}

export default flatTopGatesPage

export const flatTopGatesPageQuery = graphql`
  query flatTopGatesPageQuery {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
  }
`
